<template>
  <div class="content">
    <div
      class="login-flex toggleVis"
      :class="{ visOn: visOn, visOff: !visOn }"
      ref="loginScreen"
    >
      <h1 class="login-title titleAtLogin" :class="{ titleAtInfo: !atLogin }">
        ALIEN . FIT
      </h1>
      <form v-if="currentScreen == 'login'" @submit.prevent="">
        <input type="email" placeholder="Email" v-model="email" />
        <input type="password" placeholder="password" v-model="password" />
      </form>
      <SlideButton
        v-if="currentScreen == 'login'"
        text="Log In"
        side="right"
        @clicked="clicked('submit')"
        class="loginButton"
      />
      <SlideButton
        v-if="currentScreen == 'login'"
        text="Sign Up"
        side="left"
        @clicked="clicked('signup')"
      />
      <SlideButton
        v-if="currentScreen == 'login'"
        text="More Info"
        side="left"
        @clicked="clicked('info')"
      />
      <p v-if="currentScreen == 'login'" class="alwaysFree">ALWAYS FREE</p>
      <div
        v-if="currentScreen == 'info'"
        class="greetingsEarthling toggleVis"
        :class="{ visOn: visOn, visOff: !visOn }"
      >
        <img src="@/assets/greenAlienHead.png" alt="Alien face" />
        <p>Greetings Earthling,</p>
        <br />
        <br />
        <p>
          Welcome to the alien.fit training program. We are studying the
          efficacy of various training algorithms on the human body. Each will
          adapt itself to your abilities in an effort to reach maximum
          effectiveness to your desired fitness goals.
        </p>
        <br />
        <br />
        <p>
          Using the app is quite simple. After signing up and choosing a
          program, your assigned trainer will present you with exercises. Input
          your results, which you can then view in the training logs section of
          the app. The program will automatically scale in difficulty as you
          progress.
        </p>
        <br />
        <br />
        <p>
          The program is completely free for humans, all we want is your
          cooperation.
        </p>
      </div>
      <SlideButton
        v-if="currentScreen == 'info'"
        text="Sign Up"
        side="right"
        @clicked="clicked('signup')"
      />
      <SlideButton
        v-if="currentScreen == 'info'"
        text="Back"
        side="left"
        @clicked="clicked('login')"
      />
      <form v-if="currentScreen == 'signup'" @submit.prevent="">
        <input type="email" placeholder="Email" v-model="email" />
        <input type="password" placeholder="Password" v-model="password" />
        <input
          type="password"
          placeholder="Re-Enter"
          v-model="passwordVerification"
        />
        <input type="text" placeholder="Display Name" v-model="displayName" />
      </form>
      <SlideButton
        v-if="currentScreen == 'signup'"
        text="Submit"
        side="right"
        @clicked="clicked('submit')"
      />
      <SlideButton
        v-if="currentScreen == 'signup'"
        text="Back"
        side="left"
        @clicked="clicked('login')"
      />
      <p v-if="currentScreen == 'signup'" class="consultDr">
        Consult with your doctor before beginning a new fitness routine.
      </p>
      <p v-if="currentScreen == 'submit'" class="submitPlaceholder">
        Coming Soon!
      </p>
      <SlideButton
        v-if="currentScreen == 'submit'"
        text="Back"
        side="left"
        @clicked="clicked('login')"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from "@vue/reactivity";
import SlideButton from "@/components/SlideButton.vue";

export default {
  components: { SlideButton },
  setup() {
    const visOn = ref(false);
    const fadeTime = 600;
    const currentScreen = ref("login");

    visOn.value = true;

    const clicked = (destination) => {
      visOn.value = false;
      setTimeout(() => {
        currentScreen.value = destination;
        visOn.value = true;
        window.scrollTo(0, 0);
      }, fadeTime);
    };

    return {
      visOn,
      currentScreen,
      clicked,
    };
  },
};
</script>

<style>
.login-flex {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  width: 100%;
  height: 100%;
}

.loginButton {
  margin-top: -6vh;
}
.greetingsEarthling {
  padding: 0 50px;
}
.greetingsEarthling img {
  display: inline;
  max-width: 75px;
  margin-right: 20px;
  float: left;
}
.greetingsEarthling p {
  display: inline;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  color: var(--white-text-dimmed);
}
@media screen and (max-height: 800px) {
  .greetingsEarthling p {
    font-size: 0.7rem;
  }
}
.toggleVis {
  opacity: 0;
  transition: opacity 0.5s;
}
.toggleVis.visOn {
  opacity: 1;
}
.toggleVis.visOff {
  opacity: 0;
}
.consultDr {
  margin: 0 auto 2vh auto;
  width: 75%;
  color: var(--alien-teal-dimmed);
  font-size: 0.8rem;
}
@media screen and (max-height: 450px) {
  input,
  textarea {
    font-size: 0.3rem;
    font: bold;
    color: var(--white-text-bright);
    border: 1px solid var(--alien-teal-dimmed);
    border-radius: 8px;
    padding: 7px;
    outline: none;
    display: block;
    width: 90%;
    box-sizing: border-box;
    margin: 5px auto;
    background: var(--accent-black);
    max-width: 350px;
  }
}
.alwaysFree {
  position: absolute;
  color: var(--alien-teal-bright);
  transform: rotate(90deg);
  right: -25px;
  bottom: 18vh;
}
.submitPlaceholder {
  margin: 0 auto;
  font-size: ;
}
</style>