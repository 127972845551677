<template>
  <div class="slider-button">
    <button
      class="mechanism-btn"
      :class="{
        rightActive: isActive && !isLeft,
        leftActive: isActive && isLeft,
      }"
      @click="handleClick"
    >
      {{ text }}
    </button>
    <div
      class="mechanism-orb"
      :class="{ mechanismOrbActive: isActive, orbLeft: isLeft }"
      @click="handleClick"
    ></div>
    <div class="mechanism-line" :class="{ lineLeft: isLeft }"></div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";

export default {
  props: ["side", "text"],
  setup(props, context) {
    const isActive = ref(false);
    const isLeft = ref(false);

    if (props.side == "left") {
      isLeft.value = true;
    } else {
      isLeft.value = false;
    }

    const handleClick = () => {
      isActive.value = !isActive.value;
      setTimeout(() => {
        context.emit("clicked");
      }, 750);
    };

    return { isActive, handleClick, isLeft };
  },
};
</script>

<style>
.slider-button {
  position: relative;
}
.mechanism-btn {
  position: relative;
  z-index: 3;
  transition: transform 0.5s, color 0.3s;
}
.rightActive {
  transform: translateX(max(6vw, 25px));
  color: var(--alien-teal-bright);
}
.leftActive {
  transform: translateX(min(-6vw, -25px));
  color: var(--alien-teal-bright);
}
.mechanism-orb {
  z-index: 4;
  position: absolute;
  top: 0px;
  right: 10px;
  width: 45px;
  height: 45px;
  border: 6px solid var(--alien-teal-bright);
  border-radius: 100%;
  box-sizing: border-box;
  background-color: var(--accent-black);
  transition: background-color 0.5s;
  cursor: pointer;
}
.mechanism-orb.orbLeft {
  left: 10px;
  top: 0px;
}
.mechanismOrbActive {
  background-color: var(--alien-teal-bright);
}
.mechanism-line {
  z-index: 2;
  position: absolute;
  top: 18px;
  right: 25px;
  width: 33%;
  height: 10px;
  border: none;
  border-radius: 1px;
  box-sizing: border-box;
  background-color: var(--alien-teal-bright);
}
.mechanism-line.lineLeft {
  left: 25px;
  top: 18px;
}
</style>